import { useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { palette } from './palette';

import { useSettingsContext } from 'src/components/settings';
import { merge } from 'lodash';
import { componentsOverrides } from './overrides';

export default function ThemeProvider({ children }) {
  const settings = useSettingsContext();

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette(settings.themeMode),
      },
      direction: settings.themeDirection,
    }),
    [
      settings.themeMode,
      settings.themeDirection,
    ]
  );

  const theme = createTheme(memoizedValue);

  theme.components = merge(componentsOverrides(theme));

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
