const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
}

export const paths = {
  landing: '/',
  auth: {
    register: `${ROOTS.AUTH}/register`,
    verifyEmail: `${ROOTS.AUTH}/verify-email`,
    login: `${ROOTS.AUTH}/login`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
  }
}
