import { forwardRef } from 'react';
import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const logo = (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
    >
      <Box
        component="img"
        src="/logo512.png"
        sx={{
          width: { xs: 34, md: 58 },
          height: { xs: 34, md: 58 },
          cursor: 'pointer',
          ...sx
        }}
      />
      <Stack
        alignItems='end'
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            fontFamily: 'Montserrat',
            lineHeight: '2rem',
            color: theme.palette.primary.main,
          }}
        >Xposed Insights</Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.45rem',
            fontFamily: 'Montserrat',
            lineHeight: '0.55rem',
            color: theme.palette.common.black,
          }}
        >Unveiling the truth behind every job application</Typography>
      </Stack>
    </Stack>
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
})

export default Logo;
