import {
  AppBar,
  Stack,
  Toolbar,
  Link,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { HEADER } from '../config-layout';
import Logo from 'src/components/logo';
import { useResponsive } from 'src/hooks/use-responsive';
import HeaderMenu from './header-menu';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function Header() {
  const theme = useTheme();
  const mdup = useResponsive('up', 'md');
  const router = useRouter();
  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Logo />
        {
          mdup ? <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Link color="inherit" href="#">
                Home
              </Link>
              <Link color="inherit" href="#">
                About
              </Link>
              <Link color="inherit" href="#">
                How it works
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button variant="outlined">Join Now</Button>
              <Button variant="contained" onClick={() => router.push(paths.auth.login)}>Log in</Button>
            </Stack>
          </> : <Stack direction="row" alignItems="center">
            <Button variant="contained" onClick={() => router.push(paths.auth.login)}>Log in</Button>
            <HeaderMenu />
          </Stack>
        }

      </Toolbar>
    </AppBar>
  );
}
