import { useState, useRef } from 'react'
import {
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  MenuItem,
  Menu,
  Link,
  Button,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const theme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '0 0',
          '&.Mui-expanded': {
            margin: '0px',
            minHeight: 'auto',
          },
        },
      },
    },
  },
})

const HEADER_LINKS = [
  {
    name: 'Why Xposed Insights',
    category: 'why-exposed',
    links: [{
      name: 'Get Started Today',
      to: '#'
    }, {
      name: 'How It Works',
      to: 'how-it-works',
    }],
  },
  {
    name: 'Learn',
    category: 'learn',
    links: [{
      name: 'FAQs',
      to: 'faqs',
    }, {
      name: 'Support',
      to: 'support',
    }, {
      name: 'Community Guidelines',
      to: 'community-guidelines',
    }, {
      name: 'Blogs',
      to: 'blogs',
    }],
  },
  {
    name: 'About Us',
    category: 'about-us',
    links: [{
      name: 'Contact Us',
      to: 'contact-us',
    }, {
      name: 'Team',
      to: 'team',
    }]
  }
];

export default function HeaderMenu() {
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState('')
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Box>
      <IconButton
        ref={anchorRef}
        id="header-menu-button"
        aria-controls={open ? 'header-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{ padding: 0, marginLeft: '1rem' }}
        onClick={handleToggle}
      >
        <Box
          component="img"
          src="Frame.png"
          alt="bars-3-bottom-right"
        />
      </IconButton>

      <Menu
        id="header-menu"
        aria-labelledby="header-menu-button"
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiMenu-list': {
            paddingY: 0,
          },
          '& .MuiMenu-paper': {
            padding: '24px 30px 10px 30px',
            backgroundColor: '#F7F7F7',
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto',
          },
        }}
        onKeyDown={handleListKeyDown}
        disableScrollLock={true}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '35px',
          }}
        >
          <Button variant="outlined" fullWidth>Get Started</Button>
        </Box>
        {
          HEADER_LINKS.map((group) => (
            <MenuItem
              key={group.category}
              sx={{
                padding: '0 0 0.675rem 0',
                marginBottom: '0.675rem',
                borderBottom: '1px solid #ebebeb',
                width: '15rem',
                textTransform: 'capitalize',
              }}
              onClick={() => { }}
            >
              <ThemeProvider theme={theme}>
                <Accordion
                  sx={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                  expanded={expanded === `panel-${group.category}`}
                  onChange={handleChange(`panel-${group.category}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      group.links.length ? (
                        <ExpandMoreIcon />
                      ) : null
                    }
                    aria-controls={`panel-${group.category}`}
                    id={`panel-${group.category}`}
                    sx={{
                      padding: 0,
                      content: {
                        margin: '0 0',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        padding: '10px',
                        color: '#101010',
                        fontSize: '1.125rem',
                        fontFamily: 'Montserrat',
                      }}
                    >
                      {group.name}
                    </Typography>
                  </AccordionSummary>
                  {group.links.length ? (
                    <AccordionDetails sx={{ padding: '0.5rem 1.5rem 0 1.5rem' }}>
                      {group.links.map((link) => {
                        return (
                          <Link
                            key={link.to}
                            display="block"
                            width="100%"
                            textAlign="left"
                            marginBottom="1.25rem !important"
                            padding={0}
                            fontSize="0.875rem"
                            color="#101010"
                            sx={{
                              textDecoration: 'none',
                              '&:last-child': {
                                marginBottom: '0 !important',
                              },
                            }}
                            fontFamily="Open sans"
                            href="#"
                          >
                            {link.name}
                          </Link>
                        )
                      })}
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              </ThemeProvider>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  )
}
