import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function Strong({ children }) {
  const theme = useTheme();

  return (
    <Typography
      component="span"
      color={theme.palette.primary.main}
      fontSize="inherit"
      fontFamily="inherit"
      lineHeight="inherit"
    >
      {children}
    </Typography>
  );
}
