export const HEADER = {
  H_MOBILE: 59,
  H_DESKTOP: 112,
  H_DESKTOP_OFFSET: 112 - 20,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
