import {
  lazy,
  Suspense,
} from 'react';
import {
  Navigate,
  useRoutes,
} from "react-router-dom";

import { PATH_AFTER_LOGIN } from "src/config-global";

import { dashboardRoutes } from "./dashboard";
import { mainRoutes } from "./main";
import { authRoutes } from './auth';
const HomePage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <HomePage />
        </Suspense>
      ),
    },
    ...authRoutes,
    ...dashboardRoutes,
    ...mainRoutes,
  ]);
}
