import { alpha } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';

const COLORS = ['primary', 'warning'];

export function button(theme) {
  const lightMode = theme.palette.mode === 'light';

  const rootStyles = (ownerState) => {
    const inheritColor = ownerState.color === 'inherit';
    const containedVariant = ownerState.variant === 'contained';
    const outlinedVariant = ownerState.variant === 'outlined';
    const textVariant = ownerState.variant === 'text';
    const softVariant = ownerState.variant === 'soft';
    const smallSize = ownerState.size === 'small';
    const mediumSize = ownerState.size === 'medium';
    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      textTransform: 'none',
      fontFamily: 'Open sans',
      ...(inheritColor && {
        ...(containedVariant && {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          }
        }),
        ...(outlinedVariant && {
          color: theme.palette.common.white,
          borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        ...(textVariant && {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
        ...(outlinedVariant && {
          '&:hover': {
            borderColor: 'currentColor',
            boxShadow: '0 0 0 0.5px currentColor',
          },
        }),
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        ...(containedVariant && {
          color: theme.palette.common.white,
          '&:hover': {

          }
        })
      })
    }));

    return [defaultStyle, ...colorStyle];
  }

  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => rootStyles(ownerState),
      },
    },
  };
}
