// ----------------------------------------------------------------------

export function link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontFamily: 'Open sans !important'
        },
      },
    },
  };
}
