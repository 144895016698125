import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "src/auth/guard";

const LoginPage = lazy(() => import('src/pages/auth/login'));
const VerifyEmailPage = lazy(() => import('src/pages/auth/verify-email'));

const auth = {
  path: 'auth',
  element: (
    <Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      )
    },
    {
      path: 'verify-email/:token',
      element: (
        <VerifyEmailPage />
      )
    }
  ],
}

export const authRoutes = [
  {
    path: '/',
    children: [auth],
  }
];
