import {
  lazy,
} from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from 'src/layouts/compact';

const HomePage = lazy(() => import('src/pages/home'));

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>      
    ),
    children: [
      { path: '/', element: <HomePage /> },
    ],
  }
];
