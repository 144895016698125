import {
  lazy,
  Suspense,
} from 'react';

import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';

const IndexPage = lazy(() => import('src/pages/dashboard'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <Suspense fallback={<p>Loading...</p>}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
    ],
  }
];
