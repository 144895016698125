import merge from 'lodash/merge';

import { appBar } from './components/appbar';
import { link } from './components/link';
import { button } from './components/button';
import { defaultProps } from './default-props';

export function componentsOverrides(theme) {
  const components = merge(
    defaultProps(theme),
    appBar(theme),
    link(theme),
    button(theme),
  );

  return components;
}
