import ThemeProvider from 'src/theme';
import Router from "src/routes/sections";
import { AuthProvider } from './context';
import { SnackbarProvider } from './components/snackbar';

import './global.css';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
