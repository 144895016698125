import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Divider, Link, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Logo from "src/components/logo";
import { TwitterImg, LinkedinImg, FacebookImg } from "../assets";
import { useResponsive } from "src/hooks/use-responsive";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import Strong from "src/components/strong/strong";

const FOOTER_LINKS = [
  {
    category: 'company',
    name: 'COMPANY',
    links: [{
      name: 'About',
      to: 'about',
    }, {
      name: 'Contact Us',
      to: 'contact-us',
    }],
  }, {
    category: 'help',
    name: 'HELP',
    links: [{
      name: 'Customer Support',
      to: 'customer-support',
    }, {
      name: 'Delivery Details',
      to: 'delivery-details',
    }, {
      name: 'Terms & Conditions',
      to: 'terms-conditions',
    }, {
      name: 'Privacy Policy',
      to: 'privacy-policy',
    }],
  }, {
    category: 'resources',
    name: 'RESOURCES',
    links: [{
      name: 'Free eBooks',
      to: 'free-ebooks',
    }, {
      name: 'Development Tutorial',
      to: 'development-tutorial',
    }, {
      name: 'How to Blog',
      to: 'how-to-blog',
    }, {
      name: 'Youtube Playlist',
      to: 'youtube-playlist',
    }],
  },
];

export default function Footer() {
  const theme = useTheme();
  const mdup = useResponsive('up', 'md');
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box component="footer" sx={{
      paddingTop: '3rem',
    }}>
      <Container maxWidth="xl">
        <Stack
          direction={{
            md: 'row',
            xs: 'column',
          }} justifyContent="space-between"
          paddingBottom="3rem"
        >
          <Stack gap="1.5rem" maxWidth={{
            md: '21rem',
          }} marginBottom="2rem">
            <Logo disabledLink />
            <Typography
              fontFamily="Open sans"
              marginBottom="1rem"
            >
              Clarity gives you the blocks and components you need to create a truly professional website.
            </Typography>
            <Stack direction="row" gap="1rem">
              <Box component="img" src={TwitterImg} alt="Twitter logo" />
              <Box component="img" src={FacebookImg} alt="Facebook logo" />
              <Box component="img" src={LinkedinImg} alt="Linkedin logo" />
            </Stack>
          </Stack>
          {
            mdup ? FOOTER_LINKS.map(group => (
              <Stack key={group.category} gap="2rem">
                <Typography sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  textTransform: 'uppercase',
                  lineHeight: '1.5rem',
                }}>{group.name}</Typography>
                {
                  group.links.map(link => (
                    <Link key={link.name} href="#" sx={{
                      fontSize: '1rem',
                      color: theme.palette.common.black,
                    }}>
                      {link.name}
                    </Link>
                  ))
                }
              </Stack>
            )) : FOOTER_LINKS.map(group => (
              <Accordion key={group.category}
                expanded={expanded === group.name}
                onChange={handleChange(group.name)}
                sx={{
                  boxShadow: 'none',
                  '&.Mui-expanded': {
                    margin: 0,
                  },
                  fontFamily: 'Open sans',
                  borderBottom: '1px solid #ebebeb',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={group.name} id={group.category} sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                }}>
                  {group.name}
                </AccordionSummary>
                <AccordionDetails sx={{
                  paddingLeft: '2rem',
                }}>
                  {
                    group.links.map(link => (
                      <Link key={link.name} href="#" sx={{
                        color: theme.palette.common.black,
                        display: 'block',
                        marginBottom: '0.8rem',
                        '&:hover': {
                          textDecoration: 'underline',
                        }
                      }}>
                        {link.name}
                      </Link>
                    ))
                  }
                  </AccordionDetails>
              </Accordion>
            ))
          }
        </Stack>
        <Divider />
        <Typography
          textAlign="center"
          fontSize="0.875rem"
          fontFamily="Open sans"
          padding="2rem 0"
        >© Copyright 2022, All Rights Reserved by <Box component="span" sx={{
          fontWeight: 'bold',
        }}>Xposed Insights</Box></Typography>
      </Container>
    </Box>
  );
}
