import { useMemo, useEffect, useReducer, useCallback } from "react";

import { AUTHENTICATED, INITIAL, LOADING, LOGIN, LOGOUT, UNAUTHENTICATED, VERIFY_EMAIL } from "./consts";
import { AuthContext } from "./auth-context";
import http from "src/services/http";

const initialState = {
  user: null,
  token: null,
  loading: false,
};

const reducer = (state, action) => {
  if (action.type === INITIAL) {
    return {
      loading: false,
      token: action.payload.token,
      user: action.payload.user,
    }
  }

  if (action.type === LOGIN) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    window.localStorage.setItem("token", action.payload.token);
    return {
      ...state,
      token: action.payload.token,
      user: action.payload.user,
    }
  }

  if (action.type === VERIFY_EMAIL) {
    window.localStorage.setItem("user", JSON.stringify(action.payload.user));
    window.localStorage.setItem("token", action.payload.token);
    return {
      ...state,
      token: action.payload.token,
      user: action.payload.user,
    }
  }

  if (action.type === LOGOUT) {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    return initialState;
  }
  return state;
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const user = window.localStorage.getItem("user") ?
      JSON.parse(window.localStorage.getItem('user')) : null;
    const token = window.localStorage.getItem("token") || null;

    dispatch({
      type: INITIAL,
      payload: {
        user,
        token,
      },
    });
  }, []);

  const login = useCallback(async (email, password) => {
    const { data } = await http.post(`/login`, {
      email,
      password,
    });
    if (data.status) {
      const { user, token } = data;
      dispatch({
        type: LOGIN,
        payload: {
          user,
          token,
        },
      });
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const verifyEmail = useCallback(async (token, { signal }) => {
    const { data } = await http.get(`/verify-email/${token}`, { signal });
    if (data.status) {
      const { user, token } = data;
      dispatch({
        type: VERIFY_EMAIL,
        payload: {
          user,
          token,
        },
      });
    }
    return {
      msg: data.msg,
      status: data.status,
    }
  }, []);

  const logout = useCallback(() => {
    dispatch({
      type: LOGOUT,
    });
  }, []);

  const checkAuthenticated = !!state.user && !!state.token ? AUTHENTICATED : UNAUTHENTICATED;

  const status = state.loading ? LOADING : checkAuthenticated;

  const memoizedValue = useMemo(() => ({
    user: state.user,
    loading: status === LOADING,
    authenticated: status === AUTHENTICATED,
    unauthenticated: status === UNAUTHENTICATED,
    login,
    verifyEmail,
    logout,
  }), [state.user, login, verifyEmail, logout]);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
